<script lang="ts">
  import { generateThemeBackgroundColors } from "@shared/util/accountTheme"
  import { applyThemeBackgroundColors } from "@helpers/accountTheme"
  import { merchant } from "@/store"

  $: if ($merchant.theme.theme_background_base_hex_color) {
    const colors = generateThemeBackgroundColors($merchant.theme.theme_background_base_hex_color)
    applyThemeBackgroundColors(colors)
  }
</script>

<script>
  import link from "@actions/use-link"

  export let href
  export let disabled = false
  export let external = false
  export let forceSelf = false
  export let hard = false
  export let type = "primary"
  export let tabindex = undefined
  export { className as class }

  let className = ""

  const types = {
    none: "",
    stroke: "link--stroke [ text-stroke ]",
    primary: "link--primary [ text-primary ]",
  }
</script>

{#if href}
  <a
    {tabindex}
    {href}
    on:click
    use:link={!external || hard}
    target={external && !forceSelf ? "_blank" : "_self"}
    rel={external && "nofollow noreferrer"}
    class="{className} link {types[type]}"
  >
    <slot />
  </a>
{:else}
  <button
    {tabindex}
    type="button"
    {disabled}
    on:click
    class:opacity-75={disabled}
    class:pointer-events-none={disabled}
    class="{className} link {types[type]}"
  >
    <slot />
  </button>
{/if}

import { writable } from "svelte/store"

const notifications = []

function newNotification(parameters) {
  return {
    id: Math.random().toString(36),
    ...parameters,
  }
}

function createNotificationStore() {
  const { subscribe, set, update } = writable(notifications)

  return {
    subscribe,

    add: (parameters) =>
      update(function (notifications) {
        return [newNotification(parameters), ...notifications]
      }),

    remove: (notification) =>
      update(function (notifications) {
        return notifications.filter((item) => item.id !== notification.id)
      }),

    reset: () => set(notifications),

    success: function (parameters) {
      this.add({ type: "success", ...parameters })
    },

    error: function (parameters) {
      this.add({ type: "error", ...parameters })
    },
  }
}

const store = createNotificationStore()

export default store

<script>
  export let top = false
  export let right = false
  export let bottom = false
  export let left = false
</script>

<span class="[ relative inline-block ]">
  <span
    class="[ absolute z-content ]"
    class:top-0={top}
    class:right-0={right}
    class:bottom-0={bottom}
    class:left-0={left}
  >
    <slot name="badge" />
  </span>

  <slot />
</span>

<script>
  export let name
  export let weight = ""
  export { className as class }

  let className = ""
</script>

{#if weight}
  <i class="c-icon ri-{name}-{weight} {className}" aria-hidden="true" />
{:else}
  <i class="c-icon ri-{name} {className}" aria-hidden="true" />
{/if}

import { router } from "@inertiajs/svelte"

export default class Analytics {
  static install() {
    if (!window.analyticsData) {
      return
    }

    Analytics.identify(window.analyticsData.user)

    // This event fires on both initial load and page change
    router.on("navigate", async () => {
      Analytics._page()
    })
  }

  static track(event, properties) {
    if (!window.analyticsData) {
      return
    }

    window.analytics.track(event, properties)
  }

  static _page() {
    if (!window.analyticsData) {
      return
    }

    window.analytics.page.apply(window.analytics, arguments)
  }

  static identify(user) {
    if (!window.analyticsData) {
      return
    }

    if (user.id) {
      // https://segment.com/docs/connections/spec/identify/
      window.analytics.identify(user.id, {
        name: user.fullName,
        email: user.email,
        type: user.type,
        createdAt: user.createdAt,
        updatedAt: user.updatedAt,
      })
    }
  }
}

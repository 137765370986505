export default class Front {
  static install() {
    const settings = window.frontSettings
    if (settings === undefined) {
      return
    }

    window.FrontChat("init", settings)
  }
}

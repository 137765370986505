import inflection from "inflection"

export default function plural(word, count) {
  count = count || 0

  if (count.hasOwnProperty("length")) {
    count = count.length
  }

  if (count === 1) {
    return inflection.singularize(word)
  }

  return inflection.pluralize(word)
}

<script>
  export let size = "full"
  export { className as class }

  const Sizes = {
    tiny: "wrap--tiny [ max-w-[36rem] ]",
    small: "wrap--small [ max-w-[42rem] ]",
    medium: "wrap--medium [ max-w-[56rem] ]",
    large: "wrap--large [ max-w-[64rem] ]",
    huge: "wrap--huge [ max-w-[72rem] ]",
    full: "wrap--base [ max-w-[90rem] ]",
  }

  let className = ""
</script>

<div class="{className} wrap {Sizes[size]} [ mx-auto w-full ]">
  <slot />
</div>

<script>
  import notifications from "@store/notifications"
  import Notification from "@components/Notification.svelte"
</script>

{#if $notifications.length > 0}
  <div class="c-notification-drawer [ fixed bottom-4 right-4 space-y-4 z-notification max-w-[350px] w-full ]">
    {#each $notifications as notification (notification.id)}
      <Notification {notification} />
    {/each}
  </div>
{/if}

import { router } from "@inertiajs/svelte"

export default class Affirm {
  static install() {
    // This event fires on both initial load and page change
    router.on("navigate", async () => {
      setTimeout(() => {
        window.affirm?.ui?.refresh?.()
      }, 0)
    })

    // This event fires on initial load, as well as for things like changing
    // line item quantity/shipping options in the checkout flow.
    router.on("success", async () => {
      setTimeout(() => {
        window.affirm?.ui?.refresh?.()
      }, 0)
    })
  }
}

<script lang="ts">
  import { Modals } from "svelte-modals"

  import AppHeader from "@layouts/AppHeader.svelte"
  import AppFooter from "@layouts/AppFooter.svelte"
  import NotificationDrawer from "@components/NotificationDrawer.svelte"
  import AccountThemeManager from "@components/AccountThemeManager.svelte"
</script>

<div class="app min-h-screen">
  <a
    href="#content"
    type="neutral"
    tabindex="0"
    class="
      app__skip-to-content
      [ bg-neutral-100 px-4 py-2 ]
      [ absolute top-0 left-0 opacity-0 focus:opacity-100 ]
    "
  >
    Skip to main content
  </a>

  <AccountThemeManager />

  <AppHeader />

  <main id="content">
    <slot />
  </main>

  <div class="sticky top-[100vh]">
    <AppFooter />
  </div>

  <Modals />

  <NotificationDrawer />
</div>

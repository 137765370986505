<script>
  export { className as class }

  let className = ""

  let animating = false
</script>

<div class={className}>
  <svg
    viewBox="0 0 222 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="h-full"
    on:mouseenter={() => (animating = true)}
    on:mouseleave={() => (animating = false)}
  >
    <g class="sd-combomark-wrapper" class:sd-combomark-wrapper--animating={animating}>
      <g class="sd-combomark-door">
        <path
          d="M213.402 8.46923C216.583 8.46923 218.591 10.324 218.591 13.2289C218.591 16.103 216.552 17.9885 213.371 17.9885H207.163V8.46923H213.402ZM205.492 28.5501H207.163V19.5291H213.371L218.468 28.5501H220.353L215.103 19.2225C218.284 18.732 220.292 16.4096 220.292 13.2289C220.292 9.39663 217.602 6.92867 213.494 6.92867H205.492V28.5501V28.5501Z"
          class="sd-combomark-path"
        />
        <path
          d="M180.314 17.7356C180.314 12.2095 183.74 8.28528 188.684 8.28528C193.658 8.28528 197.084 12.2095 197.084 17.7356C197.084 23.2616 193.658 27.1859 188.684 27.1859C183.748 27.1859 180.314 23.2616 180.314 17.7356ZM198.786 17.7356C198.786 11.3434 194.647 6.73706 188.684 6.73706C182.752 6.73706 178.582 11.3434 178.582 17.7356C178.582 24.1277 182.752 28.7341 188.684 28.7341C194.647 28.7341 198.786 24.1277 198.786 17.7356Z"
          class="sd-combomark-path"
        />
        <path
          d="M153.412 17.7356C153.412 12.2095 156.838 8.28528 161.782 8.28528C166.756 8.28528 170.182 12.2095 170.182 17.7356C170.182 23.2616 166.756 27.1859 161.782 27.1859C156.838 27.1859 153.412 23.2616 153.412 17.7356ZM171.883 17.7356C171.883 11.3434 167.745 6.73706 161.782 6.73706C155.849 6.73706 151.68 11.3434 151.68 17.7356C151.68 24.1277 155.849 28.7341 161.782 28.7341C167.745 28.7341 171.883 24.1277 171.883 17.7356Z"
          class="sd-combomark-path"
        />
        <path
          d="M129.476 8.46924H134.113C140.014 8.46924 143.287 12.0562 143.287 17.7356C143.287 23.392 140.014 27.0019 134.113 27.0019H129.476V8.46924ZM134.105 28.5501C140.965 28.5501 144.981 24.2887 144.981 17.7356C144.981 11.1595 140.965 6.92101 134.105 6.92101H127.805V28.5425H134.105V28.5501Z"
          class="sd-combomark-path"
        />
      </g>

      <g class="sd-combomark-key-unlocker">
        <g class="sd-combomark-key">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M100.083 21.0083L89.8122 10.7379L100.083 0.467533L110.353 10.7379L100.083 21.0083ZM100.083 18.8469L91.9812 10.7456L100.09 2.64424L108.184 10.7456L100.083 18.8469Z"
            class="sd-combomark-path sd-combomark-key-head"
          />

          <path
            d="M99.2855 19.9276H100.811V35.3409H99.2855V19.9276Z"
            class="sd-combomark-path sd-combomark-key-shaft"
          />

          <path
            d="M100.405 26.542H105.754L105.762 28.0749H100.405V26.542Z"
            class="sd-combomark-path sd-combomark-key-tooth-1"
          />
          <path
            d="M100.404 30.3743H103.47V31.9072H100.404V30.3743Z"
            class="sd-combomark-path sd-combomark-key-tooth-2"
          />
        </g>
      </g>

      <g class="sd-combomark-side">
        <path
          d="M59.0777 28.5501H72.7895V27.0019H60.7409V18.2568H70.5054V16.7162H60.7409V8.46924H72.3909V6.92101H59.0777V28.5501Z"
          class="sd-combomark-path"
        />
        <path
          d="M36.092 8.46924H40.729C46.6306 8.46924 49.9033 12.0562 49.9033 17.7356C49.9033 23.392 46.6306 27.0019 40.729 27.0019H36.092V8.46924ZM40.729 28.5501C47.5887 28.5501 51.6048 24.2887 51.6048 17.7356C51.6048 11.1595 47.5887 6.92101 40.729 6.92101H34.4288V28.5425H40.729V28.5501Z"
          class="sd-combomark-path"
        />
        <path d="M26.2125 6.92101H24.5416V28.5425H26.2125V6.92101Z" class="sd-combomark-path" />
        <path
          d="M0.766449 21.905C0.981054 25.9825 3.9472 28.7341 8.79881 28.7341C13.3362 28.7341 16.333 26.2661 16.333 22.6178C16.333 18.5097 12.6847 17.4597 9.10539 16.8388C5.802 16.2793 2.92783 15.7275 2.92783 12.6387C2.92783 9.82585 5.11987 8.25463 8.51522 8.25463C11.7573 8.25463 13.98 10.0788 14.3555 12.8916H16.0571C15.7505 9.18203 12.9376 6.74473 8.52289 6.74473C4.13881 6.74473 1.26464 9.0594 1.26464 12.7383C1.26464 16.8465 4.78263 17.7739 8.24697 18.3564C11.6423 18.9466 14.6698 19.621 14.6698 22.7711C14.6698 25.5533 12.3245 27.2165 8.82947 27.2165C5.18118 27.2165 2.71322 25.1165 2.49862 21.905H0.766449V21.905Z"
          class="sd-combomark-path"
        />
      </g>
    </g>

    <defs>
      <style>
        @keyframes sd-combomark-anim-key-enter-exit {
          0% {
            transform: rotate(0deg);
          }

          15% {
            transform: rotate(-95deg);
          }

          20% {
            transform: rotate(-90deg);
          }

          60% {
            transform: rotate(-90deg);
          }

          75% {
            transform: rotate(5deg);
          }

          80% {
            transform: rotate(-4deg);
          }

          85% {
            transform: rotate(2deg);
          }

          90% {
            transform: rotate(-1deg);
          }

          100% {
            transform: rotate(0deg);
          }
        }

        @keyframes sd-combomark-anim-key-unlock {
          25% {
            transform: rotate3d(0, 0, 0, 0deg);
          }

          38% {
            transform: rotate3d(1, 0, 0, 60deg);
          }

          42% {
            transform: rotate3d(1, 0, 0, 60deg);
          }

          55% {
            transform: rotate3d(0, 0, 0, 0deg);
          }
        }

        /* Fix transform origin */
        * {
          transform-box: fill-box;
        }

        .sd-combomark-wrapper--animating .sd-combomark-key-unlocker {
          animation: sd-combomark-anim-key-unlock 1800ms linear;
        }

        .sd-combomark-wrapper--animating .sd-combomark-key {
          animation: sd-combomark-anim-key-enter-exit 1800ms linear;
        }

        .sd-combomark-path {
          fill: currentColor;
        }

        .sd-combomark-key-unlocker {
          transform-origin: center;
        }

        .sd-combomark-key {
          transform-origin: 10px center;
        }

        .sd-combomark-key-head {
        }

        .sd-combomark-key-shaft {
        }

        .sd-combomark-key-tooth-1 {
        }

        .sd-combomark-key-tooth-2 {
        }
      </style>
    </defs>
  </svg>
</div>

import { inertia } from "@inertiajs/svelte"

export default (node, apply = true) => {
  if (!apply) {
    return {}
  }

  inertia(node)

  return {
    update() {
      inertia(node)
    },
  }
}

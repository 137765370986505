import { setCssVar } from "@shared/util/css"
import { ThemeBackgroundColors } from "@shared/util/accountTheme"

export const applyThemeBackgroundColors = (colors: ThemeBackgroundColors) => {
  setCssVar("--color-themebg-50", colors["50"])
  setCssVar("--color-themebg-100", colors["100"])
  setCssVar("--color-themebg-200", colors["200"])
  setCssVar("--color-themebg-300", colors["300"])
  setCssVar("--color-themebg-400", colors["400"])
  setCssVar("--color-themebg-500", colors["500"])
  setCssVar("--color-themebg-600", colors["600"])
  setCssVar("--color-themebg-700", colors["700"])
  setCssVar("--color-themebg-800", colors["800"])
  setCssVar("--color-themebg-900", colors["900"])
  setCssVar("--color-themebg-950", colors["950"])
}

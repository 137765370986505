import route from "@helpers/route"
import notifications from "@store/notifications"
import axios from "axios"

export const signOut = async () => {
  try {
    await axios.delete(route("shop.signin.destroy"))
    window.FrontChat("shutdown", { clearSession: true })

    window.location = route("shop.signin.create")
  } catch (err) {
    notifications.error({
      message: err.message,
    })
  }
}

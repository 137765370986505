<script>
  import { page, router } from "@inertiajs/svelte"
  import { onDestroy, onMount } from "svelte"

  import link from "@actions/use-link"
  import route from "@helpers/route"

  export let merchant

  const showsLogo = ["both", "logo"].includes(merchant.theme.branding)
  const showsName = ["both", "name"].includes(merchant.theme.branding)

  let url = route("shop.home", [merchant])

  $: externalLink = merchant.theme.logo_url_override

  function getLogoRedirectionLink() {
    if (merchant.theme.logo_url_override) {
      return merchant.theme.logo_url_override
    }

    return route("shop.home")
  }

  onMount(() => {
    url = getLogoRedirectionLink()
  })

  const removeNavigateEventListener = router.on("navigate", () => {
    url = getLogoRedirectionLink()
  })

  onDestroy(() => {
    if (removeNavigateEventListener && typeof removeNavigateEventListener === "function") {
      removeNavigateEventListener()
    }
  })
</script>

<div class="flex items-center gap-4">
  {#if showsLogo && merchant.image.url}
    <div class="max-w-[12rem] md:max-w-xl">
      <a href={url} use:link={!externalLink}>
        <img src={merchant.image.url} alt="{merchant.name} logo" class="object-contain h-[60px] md:h-[72px]" />
      </a>
    </div>
  {/if}

  {#if showsName}
    <a href={url} use:link={!externalLink}>
      <span class="text-xl md:text-2xl font-heading tracking-tight">{merchant.name}</span>
    </a>
  {/if}
</div>

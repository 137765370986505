<script>
  import Dropdown from "@components/Dropdown.svelte"

  export let type = "neutral"
  export let size = "base"
  export { className as class }

  const Types = {
    neutral: "pill--neutral bg-neutral-100",
    primary: "pill--primary bg-primary text-white",
    success: "pill--success bg-green-100 text-green-800",
    warning: "pill--warning bg-red-100 text-red-800",
  }

  const Sizes = {
    base: "pill--base px-4 py-1 font-semibold tracking-tight",
    small: "pill--small px-4 py-1 font-semibold tracking-tight text-sm",
    tiny: "pill--small px-2 py-1 font-semibold tracking-tight text-xs",
  }

  const tooltipIndicatorTypes = {
    neutral: "bg-neutral-50 shadow",
    profit: "",
    destructive: "",
  }

  let className = ""
</script>

{#if $$slots.tooltipContent}
  <Dropdown placement="top-middle">
    <svelte:fragment slot="trigger" let:toggle let:close let:openMenu>
      <div class="relative flex items-center">
        <span
          role="button"
          class="pill rounded-full font-sans cursor-help {Sizes[size]} {Types[type]} {className}"
          data-testid="pill"
          on:mouseenter={openMenu}
          on:mouseleave={close}
        >
          <slot />
          <span class="pl-1" />
        </span>
        <span
          class="absolute top-0 right-0 -mt-1.5 -mr-1.5 not-sr-only text-xs font-semibold rounded-full w-4 h-4 text-center pointer-events-none leading-[14px] font-sans pointer-events-none {tooltipIndicatorTypes[
            type
          ]}">?</span
        >
      </div>
    </svelte:fragment>

    <svelte:fragment slot="menu">
      <div class="w-[200px] bg-white text-xs font-sans p-2 shadow">
        <slot name="tooltipContent" />
      </div>
    </svelte:fragment>
  </Dropdown>
{:else}
  <span class="{className} pill rounded-full font-sans {Types[type]} {Sizes[size]}">
    <slot />
  </span>
{/if}

import chroma from "chroma-js"

import { setCssVar } from "./css";

export interface ThemeBackgroundColors {
  '50': string,
  '100': string,
  '200': string,
  '300': string,
  '400': string,
  '500': string,
  '600': string,
  '700': string,
  '800': string,
  '900': string,
  '950': string,
}

const stepAdj = (['50', '100', '200', '300', '400', '500', '600', '700', '800', '900', '950']).reduce((acc: Record<string, {cMul: number, hShift: number}>, num, i) => {
  acc[num] = {
    cMul: Math.min(1, i / 10 + 0.08),
    hShift: -2 * i,
  }

  return acc
}, {});

export const generateThemeBackgroundColors = (baseColorStr: string): ThemeBackgroundColors => {
  const baseColor = chroma(baseColorStr)
  const [bcL, bcC, bcH] = baseColor.lch()

  return {
    '50': chroma.lch(97, bcC * stepAdj['50'].cMul, bcH + stepAdj['50'].hShift).rgb().join(' '),
    '100': chroma.lch(94, bcC * stepAdj['100'].cMul, bcH + stepAdj['100'].hShift).rgb().join(' '),
    '200': chroma.lch(87, bcC * stepAdj['200'].cMul, bcH + stepAdj['200'].hShift).rgb().join(' '),
    '300': chroma.lch(77, bcC * stepAdj['300'].cMul, bcH + stepAdj['300'].hShift).rgb().join(' '),
    '400': chroma.lch(68, bcC * stepAdj['400'].cMul, bcH + stepAdj['400'].hShift).rgb().join(' '),
    '500': chroma.lch(58, bcC * stepAdj['500'].cMul, bcH + stepAdj['500'].hShift).rgb().join(' '),
    '600': chroma.lch(50, bcC * stepAdj['600'].cMul, bcH + stepAdj['600'].hShift).rgb().join(' '),
    '700': chroma.lch(40, bcC * stepAdj['700'].cMul, bcH + stepAdj['700'].hShift).rgb().join(' '),
    '800': chroma.lch(30, bcC * stepAdj['800'].cMul, bcH + stepAdj['800'].hShift).rgb().join(' '),
    '900': chroma.lch(20, bcC * stepAdj['900'].cMul, bcH + stepAdj['900'].hShift).rgb().join(' '),
    '950': chroma.lch(12, bcC * stepAdj['950'].cMul, bcH + stepAdj['950'].hShift).rgb().join(' '),
  }
}

import * as SentrySvelte from "@sentry/svelte"

export default class Sentry {
  static install() {
    if (!window.sentrySettings) {
      return
    }

    SentrySvelte.init({
      dsn: window.sentrySettings.dsn,
      environment: window.sentrySettings.environment,
      release: __VITE_COMMIT_HASH__,
      integrations: [SentrySvelte.browserTracingIntegration(), SentrySvelte.replayIntegration()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: window.sentrySettings.tracesSampleRate,
      replaysSessionSampleRate: window.sentrySettings.replaysSessionSampleRate,
      replaysOnErrorSampleRate: window.sentrySettings.replaysOnErrorSampleRate,
    })
  }
}

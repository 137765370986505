import { get, writable } from "svelte/store"
import { page } from "@inertiajs/svelte"

function newStore() {
  const { subscribe, update, set } = writable(0)

  return {
    set,
    subscribe,
    fetch() {
      update(() => Number.parseInt(get(page).props.header.total_cart_items))
    },
  }
}

export default newStore()

import { route as ziggyRoute } from "ziggy-js"
// @ts-expect-error Inertia types
import { page } from "@inertiajs/svelte"

const route = (route: string, parameters = []): string => {
  let account
  let domain

  page.subscribe((p: Record<string, Record<string, unknown>>) => {
    account = p.props.account
    domain = p.props.domain
  })()

  if (!Array.isArray(parameters)) {
    parameters = [parameters]
  }

  const sdUrlString = ziggyRoute(route, [account, ...parameters])

  if (!domain) {
    return sdUrlString
  }

  // Generate URL for custom domain
  const sdUrl = new URL(sdUrlString)
  const newHost = domain
  // Strip leading `/s/{account}`
  const newPath = sdUrl.pathname.replace(/^\/s\/\d+/, "")

  let out = `https://${newHost}${newPath}`
  if (sdUrl.searchParams.size > 0) {
    out += `?${sdUrl.searchParams.toString()}`
  }

  return out
}

export default route
